
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    RichTextField,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    ReferenceField,
    ImageField,
    BooleanField,
    BooleanInput,
    SearchInput,
    DateTimeInput,
    FunctionField,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import { useMediaQuery } from '@mui/material';
import Icon from '@mui/icons-material/Settings'
import SyncButton from '../components/SyncButton'

export const ConfigIcon = Icon

const filterArray = [
]

const FilterList = (props) => (
    <Filter {...props}>
        {filterArray}
    </Filter>
)

const ListActions = (props) => {
    console.log(props)
    return (
        <TopToolbar>
            {/* <FilterButton filters={filterArray} /> */}
            {/* <CreateButton /> */}
            {/* <ExportButton/> */}
        </TopToolbar>
    );
}

export const ConfigList = (props) => (
    <List {...props} filters={<FilterList />} actions={<ListActions />}>
        {
            useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
                <SimpleList linkType="show"
                    primaryText={record => record ? `${record.team1} - ${record.team2}` : ''}
                />
            ) : (
                <Datagrid>
                    <TextField source="fff_api" sortable={false} />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            )
        }
    </List>
)

export const ConfigShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="fff_api" />
        </SimpleShowLayout>
    </Show>
)

export const ConfigEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <TextInput source="fff_api" validate={required()} />
        </SimpleForm>
    </Edit>
)
