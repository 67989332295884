import * as React from "react"
import { Admin, Resource, DeleteButton } from 'react-admin'
import dataProvider from './dataProvider'
import authProvider from './authProvider';
import Dashboard from './Dashboard'

import { MatchList, MatchShow, MatchIcon } from './resources/matchs'
import { ConfigList, ConfigShow, ConfigEdit, ConfigIcon } from './resources/configs'
import { ArchivematchList, ArchivematchShow, ArchivematchIcon } from './resources/archivematchs'
import { ScrapperRequestList, ScrapperRequestIcon } from './resources/scrapperrequests'

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={Dashboard}
  >
    <Resource name="Match" list={MatchList} show={MatchShow} icon={MatchIcon} />
    <Resource name="Config" list={ConfigList} show={ConfigShow} edit={ConfigEdit} icon={ConfigIcon} />
    <Resource name="Archivematch" list={ArchivematchList} show={ArchivematchShow} icon={ArchivematchIcon} />
    <Resource name="ScrapperRequestsFails" list={ScrapperRequestList} icon={ScrapperRequestIcon} />
  </Admin>
)

export default App
