
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'

const API_URL = process.env.REACT_APP_API_HOSTNAME

const readEndpointsByResource = {
    'Match': 'superuser/matchs',
    'Config': 'superuser/configs',
    'Archivematch': 'superuser/archivematchs',
    'ScrapperProxy': 'superuser/scrapperproxies',
    'ScrapperRequestsFails': 'superuser/scrapperrequests/fails',
}

const writeEndpointsByResource = {
    'Match': 'superuser/match',
    'Config': 'superuser/config',
    'ScrapperProxy': 'superuser/scrapperproxy',
}

const { fetchJson } = fetchUtils

const dataProvider = {
    getList: (resource, params) => new Promise(resolve => {
        console.log('getList', { resource, params })
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort_field: field,
            sort_order: order,
            ...params.filter
        }
        const url = `${API_URL}/${readEndpointsByResource[resource]}?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            resolve({
                data: json.data,
                total: json.total
            })
        })
    }),
    getOne: (resource, params) => new Promise(resolve => {
        console.log('getOne', { resource, params })
        const url = `${API_URL}/${readEndpointsByResource[resource]}?${stringify({ id: params.id })}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            resolve({
                data: json.data[0],
            })
        })
    }),
    getMany: (resource, params) => new Promise(async (resolve) => {
        console.log('getMany', { resource, params })
        const result = []
        for (let i = 0; i < params.ids.length; i++) {
            const ret = await dataProvider.getOne(resource, { id: params.ids[i] })
            if (ret.data)
                result.push(ret.data)
        }
        resolve({ data: result })
    }),
    getManyReference: (resource, params) => new Promise(resolve => {
        console.log('getManyReference', { resource, params })
        resolve()
    }),
    create: (resource, params) => new Promise((resolve, reject) => {
        console.log('create', { resource, params })
        const url = `${API_URL}/${writeEndpointsByResource[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data, id: json.id } })
        })
    }),
    update: (resource, params) => new Promise(resolve => {
        console.log('update', { resource, params })
        delete params.data.updatedAt
        delete params.data.createdAt
        const url = `${API_URL}/${writeEndpointsByResource[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data } })
        })
    }),
    updateMany: (resource, params) => new Promise(resolve => {
        console.log('updateMany', { resource, params })
        resolve()
    }),
    delete: (resource, params) => new Promise((resolve, reject) => {
        console.log('delete', { resource, params })
        const url = `${API_URL}/${writeEndpointsByResource[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'DELETE'
        options.body = JSON.stringify({ id: params.id })
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.id } })
        })
    }),
    deleteMany: (resource, params) => new Promise(async (resolve) => {
        console.log('deleteMany', { resource, params })
        for (let i = 0; i < params.ids.length; i++) {
            await dataProvider.delete(resource, { id: params.ids[i] })
        }
        resolve({ data: [...params.ids] })
    }),
    customs: {
        calendar: (query) => new Promise(async (resolve) => {
            const url = `${API_URL}/superuser/calendar?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            return fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve({
                    data: json.data,
                })
            })
        }),
        getMatchDistricts: (query) => new Promise(async (resolve) => {
            const url = `${API_URL}/superuser/match/districts?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            return fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve({
                    data: json.data,
                })
            })
        }),
        getMatchLeagues: (query) => new Promise(async (resolve) => {
            const url = `${API_URL}/superuser/match/leagues?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            return fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve({
                    data: json.data,
                })
            })
        }),
        deleteAllRequests: () => new Promise((resolve, reject) => {
            const url = `${API_URL}/superuser/scrapperrequests`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'DELETE'
            options.body = JSON.stringify({  })
            fetchJson(url, options).then(() => {
                resolve()
            })
        }),
        syncMatch: (id) => new Promise((resolve, reject) => {
            const url = `${API_URL}/superuser/match/sync`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'PUT'
            options.body = JSON.stringify({ id })
            fetchJson(url, options).then(() => {
                resolve()
            })
        }),
    }
}

export default dataProvider
